<template>
  <!-- 产品与解决方案 -->
  <div>
    <pc-header :active1="3"></pc-header>
    <div class="main">
      <div class="NeiBanner" style="background-image: url('images/ban2.jpg')">
        <img
          style="width: 100%; height: 900px"
          :src="productBannerList.img_url"
          alt=""
        />
      </div>
      <div class="NeiNav">
        <div class="cmain">
          <ul class="lf">
            <li class="sp3">
              <a href="##">
                <span class="span3">{{ detail.title }}</span>
              </a>
            </li>
            <!-- <li class="active">
              <a href="##" class="AMM">
                <span class="span1">产品介绍</span>
                <span class="span2">DESCRIPTION</span>
              </a>
            </li> -->
          </ul>
          <div class="ToProcuts" @click="toProdcutsList">
            <a href="#">
              <em class="em1">返回列表</em>
              <em class="em2">RETURN</em>
            </a>
          </div>
        </div>
      </div>
      <div class="cmain">
        <div class="ProcutDetail">
          <div class="PDNav">
            <i></i>
            <span>产品主图</span>
          </div>
          <div class="PDThreeImg">
            <img
              v-for="(item, index) in list"
              :key="index"
              :src="item"
              alt=""
              width="400"
              height="400"
              style="margin-right: 10px"
            />
          </div>
          <div class="PDNav">
            <i></i>
            <span>产品详情</span>
          </div>
          <div
            class="content"
            style="white-space: pre-wrap"
            v-html="detail.content"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import pcHeader from "@/components/pc-header";
export default {
  components: {
    pcHeader,
  },
  data() {
    return {
      detail: {},
      list: [],
      productBannerList: [],
    };
  },
  methods: {
    toProdcutsList() {
      this.$router.push("/prodcuts").catch((err) => {});
    },
    getProductDetail(id) {
      this.$fetch({
        url: "/api/index/productDetails",
        params: { id },
      }).then((res) => {
        this.detail = res.data.data;
        this.detail.content = this.detail.content.replace(
          /<img/g,
          '<img style="max-width:100%;height:auto;display:block;"'
        );
        // this.detail.content = this.detail.content.replace(
        //   /style="/g,
        //   'style="max-width:100%;height:auto;"'
        // );
        // this.detail.content = this.detail.content.replace(/width="/g, "");
        // this.detail.content = this.detail.content.replace(/height="/g, "");

        if (this.detail.banner1) {
          this.list.push(this.detail.banner1);
        }
        if (this.detail.banner2) {
          this.list.push(this.detail.banner2);
        }
        if (this.detail.banner3) {
          this.list.push(this.detail.banner3);
        }
        if (this.detail.banner4) {
          this.list.push(this.detail.banner4);
        }
        if (this.detail.banner5) {
          this.list.push(this.detail.banner5);
        }
        // this.list.push(this.detail.banner2);
        // this.list.push(this.detail.banner3);
        // this.list.push(this.detail.banner4);
        // this.list.push(this.detail.banner5);
      });
    },
    getProductBanner() {
      this.$fetch({
        url: "/api/index/productBanner",
        params: { type: 2 },
      }).then((res) => {
        this.productBannerList = res.data.data;
      });
    },
  },
  created() {
    const id = this.$route.query.id;
    this.getProductDetail(id);
    this.getProductBanner();
  },
};
</script>
<style lang="less" scoped>
div {
  .cmain {
    .ProcutDetail {
      .content {
        width: 50%;
        margin: 0 auto;
      }
    }
  }
}
</style>